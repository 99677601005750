<template>
  <div class="wrap">
    <div class="image"
         @mousedown="draggableFun"
         @mousemove="mousemove"
         @mouseup="up"
         :style="{
          width:wrapWidth + 'px',
          height:wrapHieght + 'px',
         }">
      <div class="line"
           v-for="(item,index) in lists"
           :key="index"
           :style="{
        top:item.points.y+'px',
        left:item.points.x+'px',
        width:item.points.w+'px',
        height:item.points.h+'px'
    }">
        <span v-if="item.w">
          {{item.label}}
        </span>
      </div>
    </div>
    <div class="list">
      <div v-for="(item,index) in lists"
           @click="checkItem(index)"
           class="item"
           :key="index">
        <span>label:{{item.label}}</span>
        <span>x:{{item.points.x}}</span>
        <span>y:{{item.points.y}}</span>
        <span>w:{{item.points.w}}</span>
        <span>h:{{item.points.h}}</span>
      </div>
    </div>
    <img src="../../assets/card/card.jpg"
         id="card_img"
         alt="">
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeIndex: 0,
      active: false,
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      lists: [],
      wrapWidth: 0,
      wrapHieght: 0,
    }
  },
  mounted () {
    setTimeout(() => {
      let img = document.getElementById("card_img")
      this.wrapWidth = img.clientWidth * 0.8
      this.wrapHieght = img.clientHeight * 0.8
    }, 100);
    this.lists = [
      {
        label: 'A', points: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        }
      },
      {
        label: 'B', points: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        }
      },
      {
        label: 'C', points: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        }
      }
    ]
  },
  methods: {
    draggableFun (e) {
      this.left = e.clientX
      this.top = e.clientY

      this.$set(this.lists[this.activeIndex], 'w', 0)
      this.$set(this.lists[this.activeIndex], 'h', 0)
      this.$set(this.lists[this.activeIndex].points, 'w', 0)
      this.$set(this.lists[this.activeIndex].points, 'h', 0)
      this.active = true

      this.$set(this.lists[this.activeIndex].points, 'x', e.clientX)
      this.$set(this.lists[this.activeIndex].points, 'y', e.clientY)
    },
    mousemove (e) {
      if (this.active) {
        let width = e.clientX - this.left
        let height = e.clientY - this.top
        this.$set(this.lists[this.activeIndex].points, 'w', width)
        this.$set(this.lists[this.activeIndex].points, 'h', height)
        this.$forceUpdate()
      }
    },
    up (e) {
      this.active = false
    },
    checkItem (index) {
      this.activeIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  .image {
    background: grey;
    position: relative;
    background-image: url("../../assets/card/card.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: 1px solid grey;
    .line {
      position: absolute;
      background: red;
      opacity: 0.3;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000000;
      font-size: 20px;
    }
  }
  .list {
    margin-left: 200px;
    .item {
      height: 30px;
      cursor: pointer;
      span {
        margin-right: 10px;
      }
    }
  }
}
#card_img {
  // 把图片放到页面视图以外的位置，js获取图片宽高
  position: fixed;
  right: 1000000px;
}
</style>