var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"image",style:({
        width:_vm.wrapWidth + 'px',
        height:_vm.wrapHieght + 'px',
       }),on:{"mousedown":_vm.draggableFun,"mousemove":_vm.mousemove,"mouseup":_vm.up}},_vm._l((_vm.lists),function(item,index){return _c('div',{key:index,staticClass:"line",style:({
      top:item.points.y+'px',
      left:item.points.x+'px',
      width:item.points.w+'px',
      height:item.points.h+'px'
  })},[(item.w)?_c('span',[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()])}),0),_c('div',{staticClass:"list"},_vm._l((_vm.lists),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.checkItem(index)}}},[_c('span',[_vm._v("label:"+_vm._s(item.label))]),_c('span',[_vm._v("x:"+_vm._s(item.points.x))]),_c('span',[_vm._v("y:"+_vm._s(item.points.y))]),_c('span',[_vm._v("w:"+_vm._s(item.points.w))]),_c('span',[_vm._v("h:"+_vm._s(item.points.h))])])}),0),_c('img',{attrs:{"src":require("../../assets/card/card.jpg"),"id":"card_img","alt":""}})])}
var staticRenderFns = []

export { render, staticRenderFns }